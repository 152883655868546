import { MyESETEnvData } from './types'

const envData: MyESETEnvData = {
  ContactSupportURL: 'https://help.eset.com/getHelp?product=home_eset&lang=<0>',
  HomePortalUrl: 'https://home.eset.com/',
  DeeplinkHost: 'appreg.eset.com',
  EMSTokenIntentUrl:
    'intent://appreg.eset.com/ems/token?data={tokenData}#Intent;scheme=ems;package=com.eset.ems2.gp;end',
  EMSTokenFallbackUrl:
    'https://play.google.com/store/apps/details?id=com.eset.ems2.gp&referrer=utm_source%3Ddeeplink%26utm_content%3D{deeplink}',
  HomeAssetUrl: 'https://assets.eset.com',
}

export default envData
